(function ($) {
  $.fn.selectpicker.defaults = {
    doneButtonText: 'Închide',
    noneSelectedText: 'Nu a fost selectat nimic',
    noneResultsText: 'Nu există niciun rezultat {0}',
    countSelectedText: '{0} din {1} selectat(e)',
    maxOptionsText: ['Limita a fost atinsă ({n} {var} max)', 'Limita de grup a fost atinsă ({n} {var} max)', ['iteme', 'item']],
    selectAllText: 'Selectează toate',
    deselectAllText: 'Deselectează toate',
    multipleSeparator: ', '
  };
})(jQuery);
